<template>
  <div class="BodyParts content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="仪器编号">
                 <el-select
                  v-model="InstrumentID"
                  placeholder="请选择"
                  >
                  <el-option
                    v-for="item in Instrumentlist"
                    :key="item.InstrumentID"
                    :label="item.Name"
                    :value="item.InstrumentID"
                  ></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="类型编号">
              <el-input
                placeholder="输入类型编号搜索"
                v-model="CategoryID"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="产品名称">
              <el-input
                placeholder="输入产品名称搜索"
                v-model="Name"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择" @change="handleSearch">
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addParts"
            >新增推荐产品</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column label="仪器编号" prop="InstrumentID"></el-table-column>
        <el-table-column
          label="仪器名称"
          prop="InstrumentName"
        ></el-table-column>
        <el-table-column label="品牌编号" prop="CategoryID"></el-table-column>
        <el-table-column label="品牌名称" prop="CategoryName"></el-table-column>
        <el-table-column label="标准等级" prop="StandardID"></el-table-column>
        <el-table-column label="产品编号" prop="ProductID"></el-table-column>
        <el-table-column label="产品名称" prop="ProductName"></el-table-column>
        <el-table-column label="产品功效" prop="Effect"></el-table-column>
        <el-table-column label="产品周期" prop="PeriodID"></el-table-column>
        <el-table-column label="有效性" prop="Active">
          <template slot-scope="scope">{{
            scope.row.Active ? "有效" : "无效"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="deleter(scope.row)"
              v-if="isDelete"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      destroy-on-close
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="仪器编号" prop="InstrumentID">
                <el-select
                class="width_220"
                  v-model="ruleForm.InstrumentID"
                  placeholder="请选择"
                  >
                  <el-option
                    v-for="item in Instrumentlist"
                    :key="item.InstrumentID"
                    :label="item.Name"
                    :value="item.InstrumentID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型编号" prop="CategoryID">
                 <el-select
                  v-model="ruleForm.CategoryID"
                  placeholder="请选择"
                  class="width_220"
                >
                  <el-option
                    v-for="item in CategoryIDList"
                    :key="item.CategoryID"
                    :label="item.Name"
                    :value="item.CategoryID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="标准等级" prop="StandardID">
                <el-input
                  clearable
                  v-model="ruleForm.StandardID"
                  placeholder="请输入标准等级"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="值状态" prop="ConditionID">
                <el-input
                  clearable
                  v-model="ruleForm.ConditionID"
                  placeholder="请输入值状态"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品周期" prop="PeriodID">
                <el-input
                clearable
                class="width_220"
                  placeholder="请输入产品周期"
                  v-model="ruleForm.PeriodID"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品功效" prop="Effect">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入备注产品功效"
                  v-model="ruleForm.Effect"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="dialogtype==2">
            <el-col :span="12">
              <el-form-item label="有效性" prop="Active">
                    <el-radio v-model="ruleForm.Active" :label="true"
                      >有效</el-radio
                    >
                    <el-radio v-model="ruleForm.Active" :label="false"
                      >无效</el-radio
                    >
                  </el-form-item>
            </el-col>
          </el-row>
          
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import permission from "@/components/js/permission.js";
import API from "@/api/CRM/ResRecommend/RecommendProduct";
export default {
  name: "BodyParts",
  /**  引入的组件  */
  components: {},
  beforeCreate(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "推荐产品");
    });
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      Active: null,
      Name: "",
      CategoryID: "",
      CategoryIDList: [], //类型编号
      InstrumentID: "",
      Instrumentlist: [], //仪器编号
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      isDelete: true,
      dialogtype: 1,
      dialogVisible: false,
      tableData: [
        {
          InstrumentID: "FF33",
          InstrumentName: "名字",
          CategoryID: "GG56",
          CategoryName: "名字",
          StandardID: "GG434",
          ProductID: "P445",
          ProductName: "名字",
          Effect: "功效",
          PeriodID: "周期",
          Active: true,
          ConditionID:"01"
        },
      ],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        PeriodID: "",
        Effect: "",
        ConditionID: "",
        StandardID: "",
        CategoryID: "",
        InstrumentID: "",
      },
      rules: {
        typeCode: [
          // { required: true, message: "请输入类型编号", trigger: "blur" },
        ]
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    // 搜索
    handleSearch() {
      var that = this;
      var parmas = {
        PageNum: that.paginations.page,
        // CategoryID: that.CategoryID,
        InstrumentID: that.InstrumentID,
        Name: that.Name,
        Active: that.Active,
      };
      API.skinProductConfig(parmas).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        }
      });
    },
    // 添加弹窗
    addParts() {
      this.dialogtype = 1;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    // 删除
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑弹窗
    editor(row) {
      const { Effect, ConditionID, StandardID, CategoryID, InstrumentID,PeriodID } = row;
      this.ruleForm = {
        Effect,
        ConditionID,
        StandardID,
        CategoryID,
        InstrumentID,
        PeriodID
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    // 翻页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    // 关闭弹窗
    closeDialog() {
      this.ruleForm = {};
      this.dialogVisible = false;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createParts();
          } else {
            that.updateParts();
          }
        }
      });
    },
    // 新建
    createParts() {
      var that = this;
    var that = this;
      API.create(that.ruleForm).then(res=>{
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        }
      })
    },
    // 编辑
    updateParts() {
       var that = this;
      API.update().then(res=>{
        if (res.StateCode == 200) {
          that.$message.success("已修改!");
          that.closeDialog();
          that.handleSearch();
        }
      })
    },
    // 仪器编号
    skinInstrument() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinInstrument(params).then((res) => {
        if (res.StateCode == 200) {
          that.Instrumentlist = res.Data;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
     // 皮肤类型
    getSkinCategory() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinCategory(params).then((res) => {
        if (res.StateCode == 200) {
          that.CategoryIDList = res.List;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "推荐产品"
    );
    this.getSkinCategory();
    this.skinInstrument();
    this.handleSearch();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
